<template>

  <div>
    <indicate-list-filters
      :is-filter-indicate-sidebar-active.sync="isFilterIndicateSidebarActive"

      :search-query.sync="searchQuery"
      :filter-dates-filter.sync="filterDatesFilter"
      :campaigns-filter.sync="campaignsFilter"
      :status-filter.sync="statusFilter"
      :stage-filter.sync="stageFilter"

      :campaigns-options="campaignsOptions"
      :status-options="statusOptions"

      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>indicações</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                class="btn-icon"
                @click="isFilterIndicateSidebarActive = true"
              >
                <feather-icon
                  icon="FilterIcon"
                />

              </b-button>
            </div>
          </b-col>
          <!-- Search -->
          <!-- <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block "
                placeholder="Pesquisar..."
              />

            </div>
          </b-col> -->
        </b-row>
        <!-- / Table Top -->

      </div>
      <b-overlay
        :show="isAPICallInProgress"
        rounded="sm"
      >
        <b-table
          ref="refIndicateListTable"
          class="position-relative"
          :items="fetchRewardsVoucher"
          responsive
          :fields="tableColumns"
          primary-key="coupon"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nada Encontrado :("
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- <template #cell(stage)="{item}">
          <b-avatar
            :variant="`light-${resolveIndicateStageVariant(item.stage)}`"
          >

            <feather-icon
              :icon="resolveIndicateStageIcon(item.stage)"
              size="16"
              :class="`text-${resolveIndicateStageVariant(item.stage)}`"
            />
          </b-avatar>
        </template> -->
          <template #cell(indicator)="data">
            <div>
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.indicator.name }}
              </span>
              <small class="text-muted d-block">{{ formatNumber(data.item.indicator.phone) }}</small>
            </div>
          </template>
          <template #cell(status)="{item}">
            <div>
              <!-- resolveIndicateStatusVariant -->
              <b-badge
                pill
                :variant="resolveIndicateStatusVariant(item.status)"
              >
                {{ item.status }}
              </b-badge>
            </div>
          </template>
          <template #cell(actions)="{item}">
            <div v-if="$can('edit', 'rewardVoucher')">
              <b-button
                v-if="item.status === 'Disponivel'"
                v-b-modal.validate-reward
                variant="primary"
                class="btn-icon text-nowrap"
                :disabled="!item.indicator.cpf"
                @click="handleSelectRequestOpen(item)"
              >
                Baixar <feather-icon icon="LogInIcon" />
              </b-button>
              <span
                v-else
                class="font-weight-bold d-block text-nowrap"
              >
                {{ convertDate(item.updatedIn) }}
              </span>
            </div>
            <div v-else>
              <feather-icon icon="SlashIcon" />
            </div>
          </template>
        </b-table>
      </b-overlay>

      <!-- Footer -->
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} até {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRewards"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      v-model="isSuccessCheckoutVoucher"
      ok-title="OK"
      ok-variant="success"
      ok-only
      centered
    >
      <div class="d-flex flex-column align-items-center justify-content-center my-2">
        <feather-icon
          icon="CheckCircleIcon"
          size="45"
          class="mb-1 text-success"
        />
        <h2>Baixa realizada com sucesso!</h2>
      </div>
    </b-modal>

    <b-modal
      v-model="isInvalidCheckoutVoucher"
      ok-title="OK"
      ok-variant="danger"
      ok-only
      centered
    >
      <div class="d-flex flex-column align-items-center justify-content-center my-2">
        <feather-icon
          icon="XIcon"
          size="45"
          class="mb-1 text-danger"
        />
        <h2>Cupom cancelado</h2>
        <p>Recompensa não elegível para colaboradores.</p>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BRow, BCol, BBadge, BButton, VBModal, BTable, BPagination, BOverlay, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import formatMobileNumber from '@/utils/formatMobilePhone'
import toConvertDateTimezoneToConventionalDate from '@/utils/toConvertDateTimezoneToConventionalDate'
import IndicateListFilters from './RewardsVoucherListFilters.vue'
import useRewardVoucherList from './useRewardsVoucherList'
import rewardsVoucherStoreModule from '../rewardsStoreModule'

export default {
  components: {
    IndicateListFilters,

    BCard,
    BRow,
    BCol,
    // BFormInput,
    BTable,
    // BAvatar,
    BBadge,
    BPagination,
    BButton,
    BOverlay,

    vSelect,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  methods: {
    handleSelectRequestOpen(voucher) {
      this.$swal({
        title: 'Tem certeza que deseja dar baixa neste cupom?',
        text: 'Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.handleCheckoutVoucher(voucher.rewardToken)
        }
        return voucher
      })
    },
  },
  setup() {
    const {
      fetchRewardsVoucher,
      tableColumns,
      perPage,
      currentPage,
      totalRewards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIndicateListTable,
      refetchData,
      handleCheckoutVoucher,

      // UI
      resolveIndicateStageIcon,
      resolveIndicateStageVariant,
      resolveIndicateStatusVariant,

      // Extra Filters

      statusFilter,
      stageFilter,
      campaignsFilter,
      filterDatesFilter,

      //
      campaignsList,
      isSuccessCheckoutVoucher,
      isInvalidCheckoutVoucher,
    } = useRewardVoucherList()
    const formatNumber = number => formatMobileNumber(number)
    const convertDate = date => toConvertDateTimezoneToConventionalDate(date)

    const INDICATE_APP_STORE_MODULE_NAME = 'app-rewards-voucher'
    if (!store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.registerModule(INDICATE_APP_STORE_MODULE_NAME, rewardsVoucherStoreModule)
    onUnmounted(() => {
      if (store.hasModule(INDICATE_APP_STORE_MODULE_NAME)) store.unregisterModule(INDICATE_APP_STORE_MODULE_NAME)
    })

    const isFilterIndicateSidebarActive = ref(false)

    const statusOptions = [
      { label: 'Disponivel', value: 'Disponivel' },
      { label: 'Recebido', value: 'Recebido' },
      { label: 'Negado', value: 'Negado' },
    ]
    // onBeforeMount(() => {
    //   fetchRewardsVoucher()
    // })
    const campaignsOptions = campaignsList

    return {

      // Sidebar
      isFilterIndicateSidebarActive,

      fetchRewardsVoucher,
      tableColumns,
      perPage,
      currentPage,
      totalRewards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIndicateListTable,
      refetchData,
      handleCheckoutVoucher,

      // Filter
      avatarText,

      // UI
      resolveIndicateStageIcon,
      resolveIndicateStageVariant,
      resolveIndicateStatusVariant,
      formatNumber,
      convertDate,

      // filterDatesOptions,
      campaignsOptions,
      statusOptions,
      // stageOptions,

      // Extra Filters
      filterDatesFilter,
      campaignsFilter,
      statusFilter,
      stageFilter,
      isSuccessCheckoutVoucher,
      isInvalidCheckoutVoucher,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .swal2-popup {
    background-image: url('/images/alert-bg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 0 0;
  }
</style>
