<script>
import { computed, ref } from '@vue/composition-api'
import {
  BSidebar,
  BButton,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BSidebar,
    // BRow,
    // BForm,
    BFormInput,
    BFormGroup,
    // BFormInput,
    // BFormInvalidFeedback,
    // BButton,
    BButton,
    vSelect,
    // flatPickr,

    // Form Validation
    // ValidationProvider,
    // ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isFilterIndicateSidebarActive',
    event: 'update:is-filter-indicate-sidebar-active',
  },
  props: {
    isFilterIndicateSidebarActive: {
      type: Boolean,
      required: true,
    },

    searchQuery: {
      type: [String, null],
      default: null,
    },
    filterDatesFilter: {
      type: [String, null],
      default: null,
    },
    campaignsFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    stageFilter: {
      type: [String, null],
      default: null,
    },

    // filterDatesOptions: {
    //   type: Array,
    //   required: true,
    // },
    campaignsOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    // stageOptions: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    // const validDates = date => {
    //   const to = new Date()
    //   const from = date
    //   const diff = (to.getTime() - from.getTime()) / (1000 * 3600 * 24)
    //   return diff > 0 && diff <= 90
    // }
    const validDates = date => date.getTime() <= new Date().getTime()

    return {
      config: {
        mode: 'range',
        dateFormat: 'm/d/Y',
        altFormat: 'd/m/Y',
        altInput: true,
        enable: [
          validDates,
        ],
      },
    }
  },
  setup(props, { emit }) {
    const rangeDate = ref(null)
    const dateDiff = computed(() => {
      const rangeSplit = rangeDate.value ? rangeDate.value.split(' ') : []
      const firstDate = new Date(rangeSplit[0])
      const endDate = new Date(rangeSplit[2])
      const diff = (endDate.getTime() - firstDate.getTime()) / (1000 * 3600 * 24) + 1

      return diff
    })
    const applyFilters = () => {
      if (!rangeDate.value || dateDiff <= 90) {
        emit('refetch-data')
        emit('update:is-filter-indicate-sidebar-active', false)
      }
    }

    return {
      dateDiff,
      rangeDate,
      applyFilters,
    }
  },
}
</script>

<template>
  <b-sidebar
    id="indicate-filters-sidebar"
    :visible="isFilterIndicateSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-filter-indicate-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Filtros
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <div class="p-1">
        <b-form-group>
          <h5>Status</h5>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          >
            <template #no-options>
              <span>Não encotramos nenhum resultado :(</span>
            </template>
          </v-select>
        </b-form-group>

        <!-- <b-form-group>
          <h5>Etapa</h5>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="stageFilter"
            :options="stageOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:stageFilter', val)"
          >            <template #no-options>
            <span>Não encotramos nenhum resultado :(</span>
          </template></v-select>
        </b-form-group> -->

        <!-- <b-form-group>
          <h5>Campanha</h5>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="campaignsFilter"
            :options="campaignsOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:campaignsFilter', val)"
          >
            <template #no-options>
              <span>Não encotramos nenhum resultado :(</span>
            </template>
          </v-select>
        </b-form-group> -->

        <!-- <b-form-group>
          <h5>Data</h5>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="filterDatesFilter"
            :options="filterDatesOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:filterDatesFilter', val)"
          >
            <template #no-options>
              <span>Não encotramos nenhum resultado :(</span>
            </template>
          </v-select>
          <small
            v-if="rangeDate && !filterDatesFilter"
            class="text-danger"
          >Para filtrar por período, você deve selecionar qual tipo de data será utilizado no filtro</small>
        </b-form-group>

        <b-form-group>
          <h5>Período</h5>
          <flat-pickr
            v-model="rangeDate"
            class="form-control"
            placeholder="Selecione um período para filtrar..."
            :config="config"
          />
          <small
            v-if="dateDiff > 90"
            class="text-danger"
          >o período precisa ser no máximo de 90 dias</small>
        </b-form-group> -->

        <b-form-group>
          <h5>Palavra chave</h5>
          <b-form-input
            :value="searchQuery"
            @input="(val) => $emit('update:searchQuery', val)"
          />
        </b-form-group>
        <!-- Buttons -->
        <div class="d-flex justify-content-between mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="applyFilters"
          >
            Aplicar filtros
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#indicate-filters-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
